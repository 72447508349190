// New 2022

// He

html[dir="rtl"] {
  body {
    font-family: 'Assistant', sans-serif!important;
    * {
      font-family: 'Assistant', sans-serif!important;
    }
    p {
      text-align: justify!important;
    }
  }
  .home {
    .title-main {
      font-family: 'Geometria-Bold' !important;
    }
  }
  .title-main {
    //font-size: 60px;
    //font-weight: bold;
  }
  .title-secondary {
    font-weight: bold;
  }
  .wpml-ls-sidebars-lang_switcher {
    @media only screen and (max-width: 992px) {
      margin: 0 15px 0 0;
    }
  }
  .header-flex {
    justify-content: flex-start;
  }
  .about-story {
    padding: 0 0 72px 0;
    @media only screen and (max-width: 767px) {
      padding: 40px 0 50px 0;
    }
    &:before {
      right: initial;
      left: 0;
    }
  }
  .about-clients {
    padding: 0 0 60px 0;
    @media only screen and (max-width: 767px) {
      padding: 50px 0 30px 0;
    }
    &:before {
      left: initial;
      right: 0;
      top: -100px;
      @media only screen and (max-width: 767px) {
        top: -25px;
      }
    }
    &:after {
      display: none;
      left: initial;
      right: 0;
    }
   .wrapper {
     .text {
       padding: 0 50px 0 0;
       @media only screen and (max-width: 767px) {
         padding: 0 15px;
       }
     }
   }
  }
  .about-clients .wrapper:nth-of-type(3) .image:before {
    right: initial;
    left: -60px;
    @media only screen and (max-width: 767px) {
      left: 0;
    }
  }
  .about-values {
    &:before {
      right: initial;
      left: 0;
    }
  }
  .get-in-touch {
    &:before {
      right: initial;
      left: 0;
    }
  }
  .about-team {
    .wrapper {
      direction: ltr;
      .person-info {
        .top {
          display: inline-block;
          &:before {
            display: none;
          }
          .person-name {
            display: inline-block;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              left: calc(-75px - 15px);
              top: 15px;
              width: 75px;
              height: 3px;
              background-color: #c7b678;
              @media only screen and (max-width: 767px) {
                width: 30px;
                left: calc(-30px - 15px);
              }
            }
          }
        }
        .person-text {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          max-width: 100%;
          p {
            max-width: 816px;
            text-align: right!important;
          }
        }
      }
    }
  }
  .header {
    .logo {
      text-align: left;
    }
  }
  .header .row {
    div[class*="col-"] {
      &:first-child {
        order: 2;
      }
      &:nth-of-type(2) {
        order: 1;
      }
    }

  }
  header nav ul li a {
    font-size: 22px;
  }
  .header nav ul li:last-of-type {
    margin: 0 15px 0 0;
  }
  .header nav ul li ul li:last-of-type {
    margin: 0 0 15px 0;
  }
  .home-hero .wrapper .item {
    flex-direction: row-reverse;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  .home-hero .wrapper .item h3 {
    font-size: 20px;
    font-weight: 900;
  }
  .home-hero .wrapper .item .number {
    display: flex;
    font-size: 40px;
    font-weight: 700;
    margin: 0 25px 0 0;
    @media only screen and (max-width: 767px) {
      margin: 0 15px 0 0;
    }
  }
  .home-partners {
    .row {
      flex-direction: column-reverse;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
      div[class*="col-"] {
        width: 100%;
        max-width: 100%;
      }
    }
    .logos {
      @media only screen and (max-width: 767px) {
        align-items: flex-end;
      }
      img {
        margin: 0 30px 0 0;
        @media only screen and (max-width: 767px) {
          margin: 0 0 20px 0;
        }
      }
    }
    .text {
      .title-secondary {
        font-size: 34px;
        justify-content: flex-end;
        padding: 0 0 0 106px;
        @media only screen and (max-width: 992px) {
          font-size: 28px;
        }
        @media only screen and (max-width: 767px) {
          padding: 0 0 0 45px;
        }
        &:before {
          left: 0;
          right: initial;
        }
      }
    }
  }
  .home-platform .wrapper .item .info h3 {
    font-weight: 700;
  }
  .home-platform .wrapper .item .text ul li {
    font-size: 16px;
    line-height: 28px;
  }
  .home-platform .wrapper .item .info p {
    text-align: right!important;
  }
  .footer .row {
    div[class*="col-"] {
      &:first-child {
        order: 2;
        @media only screen and (max-width: 767px) {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        order: 1;
        @media only screen and (max-width: 767px) {
          order: 2;
        }
      }
    }
  }
  .footer .logo {
    text-align: left;
    @media only screen and (max-width: 767px) {
      text-align: right;
    }
  }
  .footer .copyright {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    .created {
      margin-bottom: 15px;
    }
  }
  .footer .copyright .created svg {
    margin: 0 10px 0 0;
  }
  .footer .wrapper .item .socials ul li {
    margin: 0 0 0 15px;
  }
  .footer .copyright p a {
    color: #fff;
    margin-left: 15px;
  }
  .get-in-touch .touch-btn {
    text-align: left;
  }
  .home-story .link .btn-en {
    display: none;
  }
  .home-story .link .btn-he {
    display: block;
  }
  .slider-transactions {
    direction: ltr;
  }
  .home-transactions .slider .slider-transactions .arrows .swiper-button-prev {
    @media only screen and (max-width: 767px) {
      order: 1;
    }
  }
  .home-transactions .slider .slider-transactions .swiper-slide .item h4 {
    display: flex;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }
  .home-platform .title-secondary {
    @media only screen and (max-width: 767px) {
      max-width: 300px;
      padding: 0 60px 0 0;
    }
  }
  .home-platform .title-secondary:before {
    @media only screen and (max-width: 767px) {
      left: 0;
      right: 15px;
    }
  }
  .title-secondary {
    @media only screen and (max-width: 767px) {
      padding: 0 45px 0 0;
    }
  }
  .home-story .title-secondary {
    @media only screen and (max-width: 767px) {
      padding: 0 60px 0 15px;
    }
  }
  .home-story .title-secondary:before {
    @media only screen and (max-width: 767px) {
      left: 0;
      right: 15px;
    }
  }
  .footer .wrapper .item {
    @media only screen and (max-width: 767px) {
      margin: 0 0 30px 20px;
    }
  }
  .modal-contact .modal-dialog .wrapper .left .title-secondary {
    @media only screen and (max-width: 767px) {
      padding: 0 70px 0 0;
    }
  }
  .get-in-touch .touch-btn {
    @media only screen and (max-width: 767px) {
      text-align: center;
      padding: 0;
    }
  }
  .private-text {
    &:after {
      display: none;
    }
  }
  .private-text .wrapper:nth-of-type(even) .image {
    text-align: left;
  }
  .private-text .wrapper:nth-of-type(odd) .image {
    text-align: right;
  }
  .header nav ul li ul li a {
    font-size: 14px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  .investment-logos {
    .text {
      justify-content: start;
      p {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .wrapper {
    .bg {
      img {
        right: initial!important;
        left: 0!important;
      }
    }
  }
  .investment-services .wrapper .item.active .info h3 {
    padding: 0 77px 0 0;
    @media only screen and (max-width: 767px) {
      padding: 0 5px 0 0;
    }
    &:before {
      top: 20px;
    }
  }
}

// Memory

.modal-memory {
  .modal-dialog {
    display: block;
    max-width: 300px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    margin: 0;
  }
  .modal-body {
    .wrapper {
      .btn-close {
        z-index: 1;
      }
      .center {
        img {
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
}

// Global

* {
  p {
    text-align: justify;
  }
}

.home-platform .wrapper .item .info p {
  text-align: left;
}

.header nav ul li {
  margin: 0 10px;
  a {
    font-size: 14px;
  }
}

.header-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 992px) {
    justify-content: flex-end;
  }
}

.wpml-ls-sidebars-lang_switcher {
  padding: 0!important;
  @media only screen and (max-width: 992px) {
    margin: 0 0 0 15px;
  }
  ul {
    display: flex;
    li {
      margin: 0 7px!important;
      &.wpml-ls-current-language {
        a {
          color: #C7B678;
          &:before {
            width: 100%;
          }
        }
      }
      a {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        padding: 0 0 11px 0;
        position: relative;
        color: #FFFFFF;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 0;
          height: 2px;
          background-color: #C7B678;
        }
      }
    }
  }
}

.home-story .link .btn-he {
  display: none;
}

.all-transactions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-top: 30px;
  transition: .3s;
  color: #173163;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  &:hover {
    transition: .3s;
    svg {
      transform: rotate(45deg);
    }
  }
  svg {
    margin-right: 10px;
    transition: .3s;
  }
}

.slider-transactions {
  direction: rtl;
}

.private-include .slider .slider-transactions .swiper-slide .item:last-of-type {
  margin-bottom: 0;
}

.footer .copyright {
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
  .created {
    @media only screen and (max-width: 992px) {
      margin-bottom: 15px;
    }
  }
  p a {
    margin-left: 15px;
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Transactions

.transactions-list {
  padding-bottom: 70px;
  @media only screen and (max-width: 768px) {
    padding: 45px 0 80px 0;
  }
  div[class*="col-"] {
    margin-bottom: 30px;
  }
  .item {
    background-color: #F7F7F7;
    height: 100%;
    padding: 30px 25px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
    .logo {
      height: 90px;
      margin-bottom: 20px;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
      color: #173163;
    }
    .number {
      direction: rtl;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 28px;
      text-transform: uppercase;
      margin-bottom: 15px;
      color: #1642A8;
    }
    .date {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 15px;
      margin-bottom: 20px;
      position: relative;
      color: #173163;
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        height: 2px;
        width: 90px;
        background-color: #C7B678;
      }
    }
    .text {
      ul {
        margin-bottom: 0;
        li {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 5px;
          color: #173163;
        }
      }
    }
  }
}